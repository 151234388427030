import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../core/api.service";
import { User } from "../model/user.model";
import { Solicitante, IUSolicitanteResponse } from "../model/solicitante.model";
import { Nucleo } from "../model/nucleo.model";
import { CPO1 } from "../model/cpo1.model";
import { CPO2 } from "../model/cpo2.model";
import { Servicos } from "../model/servicos.model";
import { Tecnicos } from "../model/tecnicos.model";
import { Protoloco } from "../model/protocolo.model";
import { Observable, from } from "rxjs";
import { COMMA, ENTER, N } from "@angular/cdk/keycodes";
import {
  map,
  startWith,
  debounceTime,
  tap,
  finalize,
  switchMap,
  distinctUntilChanged,
} from "rxjs/operators";
import { OpTec } from "../model/opTec.model";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatStepper,
  MatStepHeader,
  MatSnackBar,
  MatBottomSheet,
  MatBottomSheetRef,
  MatAutocompleteTrigger,
  throwToolbarMixedModesError,
} from "@angular/material";

import {
  MapsAPILoader,
  MouseEvent,
  AgmMap,
  AgmCoreModule,
  GeocoderLocationType,
} from "@agm/core";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  NgZone,
  ElementRef,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { GoogleMapsAPIWrapper } from "@agm/core/services";
import { SendData } from "../model/sendData.model";
import { isBoolean, isUndefined } from "util";
//import { } from "google-maps";
import { stringToKeyValue } from "@angular/flex-layout/extended/typings/style/style-transforms";
import * as prefeituras from "src/assets/mocks/prefeituras.json";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpEventType,
  // tslint:disable-next-line: import-spacing
} from "@angular/common/http";

import {
  Location,
  Appearance,
  GermanAddress,
} from "@angular-material-extensions/google-maps-autocomplete";
import PlaceResult = google.maps.places.PlaceResult;
import { FRMEDIT } from "../model/fromEdit.model";
import { TpReneg } from "../model/tpreneg.model";
import localePt from "@angular/common/locales/pt";
import {
  registerLocaleData,
  CurrencyPipe,
  formatCurrency,
} from "@angular/common";
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { HelpService } from "../help/help.service";
registerLocaleData(localePt);
import { Injectable } from "@angular/core";
import { IfStmt, ThrowStmt } from "@angular/compiler";
import { Lista } from "../model/listaIt.model";
import { trimTrailingNulls } from "@angular/compiler/src/render3/view/util";
import { ifStmt } from "@angular/compiler/src/output/output_ast";
import { CurrencyMaskDirective } from "ngx-currency";
import { Router } from "@angular/router";

@Injectable()
@Component({
  selector: "app-frmeditar",
  templateUrl: "./frmeditar.component.html",
  styleUrls: ["./frmeditar.component.css"],
})
export class FrmeditarComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public mapsAPILoader: MapsAPILoader,
    public ngZone: NgZone,
    public httpClient: HttpClient,
    public msgdialogService: MsgdialogService,
    public helpservice: HelpService,
    public router: Router,

    public _snackBar: MatSnackBar,
    public _bottomSheet: MatBottomSheet
  ) {
    this.dataTable = [];
    this.dataTableCPF = [];
    this.dataTableTecnicos = [];
    this.mapsAPILoader = mapsAPILoader;
    this.ngZone = ngZone;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    document.addEventListener(
      "keypress",
      (e) => {
        if (e.key === "Enter") {
          // console.log('kpenter', e.key)
          this.KeyPressEnter = true;
          return false;
        }
      },
      false
    );
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.key === "Enter") {
          // console.log('kdenter', e.key)
          this.KeyPressEnter = true;
          return false;
        }
      },
      false
    );
  }

  itcndk: any;
  validateSei;
  form = {
    solicitante: "",
    numero_mprj: "",
    nucleo_gate: "",
    campo_1: "",
    campo_2: "",
    servicos_tecnicos: [],
    palavras_chave: [],
    local: "",
    cpfs_cnpjs: [],
    endereco: {},
    tecnicos: [],
    opiniao_tecnica: [],
    dano_erario: "",
    valor_dano: "",
    complemento_opiniao_tecnica: "",
    nvl_proced: null,
    tipoContrato: " ",
    nr_sei: "",
    nrItVinc: "",
    inCompAnal: "N",
    inAtualVal: "N",
    atualValDano: "", // Dano Atualizado
    indicioDano: "", // Indicio de Dano
    compDano: "", // Sobrebreço
    outrosDanos: "", // Outros Danos
    danoZero: "",
    renegocia:[],
    inreng:"",
    tpInfo:"",

  };
  /*  */
  ckeckEnd = false;
  checkLat = false;
  KeyPressEnter = false;

  checkDanoS = false;
  checkDanoAuto = false;

  checkInDanoS = true;
  checkInDanoN = false;
  checkContrato = false;
  totDano = 0;
  valordano = "";
  checkFcCompS = false;
  checkFcCompJp = false;
  checkFcCompN = false;
  CompOp = true;
  checkAtValS = true;
  checkAtValN = false;
  checkcomDanoS = true;
  ChechEditEnd = false;

  CheckEditEnd;
  amount = "";
  CheckNuga = false;
  CheckNugaSaude = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  // Variaveis de Validações de Cpo1, Solicitante, CPo2, e Serviços
  selectSoli;
  selectCpo1;
  selectCpo2;
  selectSrv;
  getMsg = 0;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  checkNivel = "";
  NvProcedimento = "";
  myVariableColor = "#505050";
  ServTec: string;
  Cnpj: string;
  NmInvest: string;
  uploadForm = this.formBuilder.group({
    avatar: [null],
  });
  selectedObjectsFromArray:any
  public frm = new FormData();
  // public form = {};
  //[Validators.required, RequireMatch])
  public formEditar = new FormGroup({
    Fcsolicitante: new FormControl("", Validators.required),
    FCnumeroSei2: new FormControl(""),
    FCnucleo: new FormControl("", Validators.required),
    FCmprj: new FormControl("", Validators.required),

    FCcpo1: new FormControl(""),
    FCcpo2: new FormControl(""),
    FCServTec: new FormControl(""),
    FCopTec: new FormControl(""),

    FCTec: new FormControl("", Validators.required),
    cadastro: new FormControl(""),
    FcCkeck: new FormControl(""),
    /*           */
    FcEndRadio: new FormControl(""),
    FcEnd: new FormControl(""),
    FcEndLogradouro: new FormControl(""),
    FcEndCidade: new FormControl(""),
    FcEndBairro: new FormControl(""),
    FcEndNumero: new FormControl(""),
    FcEndComplemento: new FormControl(""),
    FcEndCep: new FormControl(""),
    FcEndLat: new FormControl(""),
    FcEndLong: new FormControl(""),

    FcEndLatNum: new FormControl(""),
    FcEndLongNum: new FormControl(""),

    FcNvl_dano: new FormControl(""),
    FcDano: new FormControl(""),
    FcComp: new FormControl(""),

    FcValCompDano: new FormControl(""), // Valor Sobre Preço
    FcValIndDano: new FormControl(""), // Valor Indicio no dano
    FcAtualValDano: new FormControl(""), // Atualização de Dano ao Erário

    FcInDano: new FormControl(""),
    FcAtDano: new FormControl(""),
    FcCnpj: new FormControl(""),
    FcNmInves: new FormControl(""),

    FcTec: new FormControl(""),
    FCnumeroSei: new FormControl(""),
    FctotContra: new FormControl(""),
    FctotContraCd: new FormControl(""),
    FcCidade: new FormControl(""),
    FcValDano: new FormControl(""),
    searchControl: new FormControl(""),
    FcArquivo: new FormControl(""),
    FcDanoS: new FormControl(""),
    FcCheckDano: new FormControl(""),
    FcTpContrato: new FormControl(""),
    Fcund: new FormControl(""),
    FcCompTec: new FormControl(""),
    //
    FcMprjError: new FormControl(""),
    Fcdzero: new FormControl(""),
    FCRengIt: new FormControl(""),
    FCtpRengIt: new FormControl(""),
    FCtpInfo: new FormControl(""),
  });
  tpContrato: TipoContrato[] = [
    { value: "I", viewValue: "Insumo" },
    { value: "O", viewValue: "Obra" },
    { value: "S", viewValue: "Serviço" },
  ];

  displayedColumnsCPF = [
    "select",
    "position",
    "delete",
    "cpf_cnpj",
    "nm_contratada",
    "num_contratos",
    "num_contratos_dano",
    "val_dano",
  ];
  displayedColumnsTecnico = [
    "select",
    "position",
    "delete",
    "tecnico",
    "check",
  ];

  displayedColumns = [
    "select",
    "position",
    "delete",
    "servico",
    "opiniao",
    "tecnico",
    "check",
  ];
  dataTable = [];
  dataTableCPF = [];
  dataTableTecnicos = [];
  // tslint:disable-next-line: variable-name
  cpfs_cnpjs = [];
  servitec = [];
  endereco: any = {};
  OptecServ = [];
  tecnico = [];
  dataSource = new MatTableDataSource<Element>(this.dataTable);
  dataSourceCPF = new MatTableDataSource<Element2>(this.dataTableCPF);
  dataSourceTecnico = new MatTableDataSource<ElementTec>(
    this.dataTableTecnicos
  );
  selection = new SelectionModel<Element>(true, []);
  selectionCPF = new SelectionModel<Element2>(true, []);
  selectionTecnico = new SelectionModel<ElementTec>(true, []);
  filteredOptions: Observable<string[]>;

  Vobj;
  vFun;
  public users: User[] = [];
  public promotorias: Solicitante[] = [];
  public cpo1: CPO1[] = [];
  public lista: Lista[] = [];
  public frmSendData: SendData;
  public nucleos: Nucleo[] = [];
  public tecnicos: Tecnicos[] = [];
  public cpo2: CPO2[] = [];
  public servicos: Servicos[] = [];
  public optec: OpTec[] = [];
  public numSei: Protoloco[] = [];
  public tpReneg: TpReneg[] = [];
  progress = 0;
  isFirstStep: boolean;
  progressBar = 0;
  public message: string;
  isLoading = false;
  public isAutenticate = false;
  filteredSolicitante: Observable<Solicitante[]>;
  filteredCpo1: Observable<any[]>;
  filteredCpo2: Observable<any[]>;
  filteredSrvTec: Observable<any[]>;
  filteredUnidades: Observable<any[]>;
  filteredITs: Observable<any[]>;
  filteredTecnitos: Observable<any[]>;
  fileData: File = null;
  fileSize;
  fileSizeMsg;
  fileUnit;
  DisableAnterior = false;

  // Maps
  @ViewChild(AgmMap, { static: false }) map: AgmMap;

  @ViewChild("search", { read: true, static: false })
  @ViewChild("stepper", { static: false })
  stepper: MatStepper;
  @ViewChild("stepperHeader", { static: false }) stepperHeader: MatStepHeader;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  trigger: MatAutocompleteTrigger;
  @ViewChild("input", { static: true }) autoTrigger: MatAutocompleteTrigger;
  public searchElementRef: ElementRef;
  public geoCoder: any;

  texto = "GATE - Grupo de Apoio Técnico Especializado";
  // latitude: number;
  // longitude: number;
  // zoom: number;
  address: string;
  usuario: string;
  nucleo: string;
  cc: string;
  firstFormGroup: FormGroup = this.formEditar;

  secondFormGroup: FormGroup;
  dados = [];
  DataCity: any = [];
  DataSaude: any = [];
  DadosEndGo: any = [];
  dadosCPF = [];
  dadosTec = [];
  renegocia = [];
  public frmedit: FRMEDIT[] = [];
  itcn_dt: any;
  /*  */
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  public contrato: any;
  public validaMprj;
  public validaCPNJ;
  public nr_info;
  public NucleoTec;
  public tpInfo;
  public nr_sintese;
  public nr_diretriz;
  public disableButton = false;

  onSubmit() {
    this.getMotivo();
    this.disableButton = true;
    //this.apiService.autenticate();
    const formDataOut = new FormData();
    //this.form.nucleo_gate = this.formEditar.get("FCnucleo").value;
    let mprj = this.formEditar.get("FCmprj").value;
    mprj = mprj.replace(/\D/g, "");
    this.form.numero_mprj = mprj;
    this.form.cpfs_cnpjs = this.cpfs_cnpjs;


    this.endereco.numero = this.formEditar.get("FcEndNumero").value;
    this.endereco.complemento = this.formEditar.get('FcEndComplemento').value;
    this.endereco.logradouro = this.formEditar.get('FcEndLogradouro').value.toUpperCase();
    this.endereco.bairro = this.formEditar.get('FcEndBairro').value.toUpperCase();

    let nrSeiFromatado = this.formEditar.get('FCnumeroSei').value;
    nrSeiFromatado = nrSeiFromatado.replace(/\D/g, '');

    if (this.formEditar.get("FCnumeroSei").value === "") {
      this.form.nr_sei = this.formEditar.get("FCnumeroSei2").value;
    } else {
      this.form.nr_sei = this.formEditar.get("FCnumeroSei").value;
    }

    const FcEndCidade = this.formEditar.get('FcEndCidade').value;
    if (this.endereco.municipio === '' && FcEndCidade !== '') {
      this.DataCity.filter((option) => {
        if (option.Cidade.indexOf(FcEndCidade) > -1) {
          this.endereco.municipio = option.id;
        }
      });
    }
    if(this.endereco.municipio <= 0){
      this.endereco.municipio = '';
    }

   if (this.endereco.cep !== '') {
    const str = this.endereco.cep;
    let newstr = "";
    if(str !== '' && str !== undefined){
       newstr = str.replace('-', '');
    }
      this.endereco.cep = newstr;
  }

    this.form.endereco = this.endereco;
    this.form.tecnicos = this.tecnico;
    this.form.servicos_tecnicos = this.servitec;
    this.form.opiniao_tecnica = this.OptecServ;
    this.form.tipoContrato = this.formEditar.get("FcTpContrato").value;
    if (this.formEditar.get("FcDano").value > 0) {
      this.form.dano_erario = "S";
      this.form.valor_dano = this.formEditar.get("FcDano").value;
    }

    if (this.formEditar.controls.FcDano.value === '' || this.formEditar.controls.FcDano.value <= 0) {
      this.form.dano_erario = "N";
      this.form.valor_dano = "0.0";
    }

    // this.form.valor_dano = this.formEditar.get("FcDano").value;
    this.form.complemento_opiniao_tecnica = this.formEditar.get(
      "FcCompTec"
    ).value;
    this.form.nvl_proced = this.formEditar.get("FcNvl_dano").value;
    if (this.formEditar.get("FcComp").value != "") {
      this.form.nrItVinc = this.formEditar.get("FcComp").value;
      this.form.inCompAnal = "S";
    }

    if (
      this.formEditar.get("FcNvl_dano").value === null ||
      this.formEditar.get("FcNvl_dano").value === ""
    ) {
      this.form.nvl_proced = "";
    }
    if (this.formEditar.get("Fcdzero").value === "") {
      this.form.danoZero = "N";
    } else {
      this.form.danoZero = this.formEditar.get("Fcdzero").value;
    }

    this.form.atualValDano = this.formEditar.get("FcAtualValDano").value;
    this.form.indicioDano = this.formEditar.get("FcValIndDano").value;
    this.form.compDano = this.formEditar.get("FcValCompDano").value;

    this.form.renegocia = this.renegocia;
    this.form.tpInfo = this.formEditar.get("FCtpInfo").value;
    //console.log("this.form.nvl_proced", this.form.nvl_proced);
    formDataOut.append("form", JSON.stringify(this.form));
    formDataOut.append("file", this.fileData);
    console.log("form", this.form);

    this.apiService
      .editarIt(formDataOut, this.itcndk)
      .subscribe((event: HttpEvent<any>) => {

        //console.log('Type', event);


        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            if (event.status !== 200) {
              this.disableButton = false;
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
              this.progressBar = 0;
            }
            break;
          case HttpEventType.UploadProgress:
            //console.log("event upload", event);
            //console.log("event loaded", event.loaded);
            //console.log("event total", event.total);
            this.progressBar = Math.round((100 * event.loaded) / event.total);
            return `File "${this.fileData}" is ${this.progressBar}% uploaded.`;

            //console.log(`Uploaded! ${this.progressBar}%`);
            break;


          case HttpEventType.Response:
            //console.log("event response", event, event.headers);
            if (event.status === 200) {
              const dt2 = {
                titulo: "Sucess",
                status: 200,
                statusText: event.body.status,
              };
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
              this.msgdialogService.openDialog(dt2);
              if (this.msgdialogService.isDialogOpen) {
                this.router.navigate(["lista"]);
              }
            } else {
              this.disableButton = false;
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
            }
            setTimeout(() => {
              this.progressBar = 0;
            }, 1500);
        }
      });
  }

  ngOnInit() {
    this.apiService.getUser().subscribe((res: any) => {
      //console.log('GETUSER',res);
      this.users = res;
      this.usuario = res.Nome;
      this.nucleo = res.Orgao;
    });
    //this.formEditar.get("FCtpRengIt").setValue([])
    this.formEditar.get("FCRengIt").setValue('N')
    this.form.inreng = "N";
    this.itcndk = this.route.snapshot.params.dk;
    this.nr_info = this.route.snapshot.params.nr;
    //console.log("IT", this.nr_info);
    /*
    this.apiService.autenticate().subscribe((res: any[]) => {
      this.users = res;
      this.isAutenticate = true;
      return res;
    });
    */
    this.apiService.getListaTpReneg().subscribe((res: TpReneg[]) => {
      this.tpReneg = res;
      //console.log("Reneg " + this.tpReneg)
    })

    this.filteredITs = this.formEditar.controls.FcComp.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterITS(value))
    );
    this.filteredUnidades = this.formEditar.controls.Fcund.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterUnidades(value))
    );

    this.apiService.getFrmEditar(this.itcndk).subscribe((res: FRMEDIT[]) => {
      this.frmedit = res;
      this.fillfilds(this.frmedit);
    });
   /*
    this.apiService.getFrmEditar(this.itcndk).subscribe((res: FRMEDIT[]) => {
      this.frmedit = res;
    });
    */
    this.apiService.getNumSei("HEITOR.SILVA").subscribe((res: Protoloco[]) => {
      this.numSei = res;
    });

    this.httpClient
      .get<any>("assets/mocks/prefeituras.json")
      .subscribe((res: any[]) => {
        this.DataCity = res;
      });

    this.httpClient.get("assets/mocks/saude.json").subscribe((data: any[]) => {
      this.DataSaude = data;
    });

    this.apiService.getListaOpinioesTecnicas().subscribe((res: OpTec[]) => {
      this.optec = res;
    });

    this.httpClient
      .get<Lista[]>(this.apiService.baseUrl + "listaItsPg?size=10000")
      .subscribe((res: any) => {
        this.lista = res.content;
      });
/*
    this.apiService.autenticate().subscribe((res: any[]) => {
      this.users = res;
      this.apiService.isAutenticate = true;
      return res;
    });
    */

    this.apiService.getNucleos().subscribe((res: Nucleo[]) => {
      this.nucleos = res;
    });

    this.apiService.getTecnicos().subscribe((res: Tecnicos[]) => {
      this.tecnicos = res;
    });
    this.filteredSolicitante = this.formEditar.controls.Fcsolicitante.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.filteredCpo1 = this.formEditar.controls.FCcpo1.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterCpo1(value))
    );

    this.filteredCpo2 = this.formEditar.controls.FCcpo2.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterCpo2(value))
    );
    this.filteredSrvTec = this.formEditar.controls.FCServTec.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterSrvTec(value))
    );
    this.filteredTecnitos = this.formEditar.controls.FcTec.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterTecnicos(value))
    );

    this.apiService.getListaOpinioesTecnicas().subscribe((res: OpTec[]) => {
      this.optec = res;
    });
    /*
    this.apiService.autenticate().subscribe((res: any[]) => {
      this.users = res;
      this.apiService.isAutenticate = true;
      return res;
    });
    */

    this.apiService.getNucleos().subscribe((res: Nucleo[]) => {
      this.nucleos = res;
    });

    this.apiService.getSolicitantes().subscribe((res: Solicitante[]) => {
      this.promotorias = res;
    });

    this.apiService.getTecnicos().subscribe((res: Tecnicos[]) => {
      this.tecnicos = res;
    });

    this.apiService.getServTec("0").subscribe((res: Servicos[]) => {
      this.servicos = res;
    });

    this.apiService.getFrmEditar(this.itcndk).subscribe((res: FRMEDIT[]) => {
      this.frmedit = res;
    });
  }
  goToList() {
    this.router.navigate(["lista"]);
  }
  public setNuga() {
    const nucleoAnd =

      "COORDENADORIA DE ANÁLISES, DIAGNÓSTICOS E GEOPROCESSAMENTO";
    /* Núcleo Técnico de Contabilidade */
    if (this.nucleo === nucleoAnd) {
      this.nucleo = "Núcleo Técnico de Contabilidade";
    }


  }
  logOut() {
    return this.httpClient.post("http://localhost:8092/gate/api/logout", {
      Accept: "*/*",
    });
  }

  public setCurrentLocation() {
    /*
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude, "city");
        this.endereco.tipo_end = "L"
      });
    }
    */
  }
  setCurrentLocationLatLong() {
    // this.getAddress(-22.91948219999999, -43.176082099999974);
    this.latitude = this.formEditar.get("FcEndLatNum").value;
    this.longitude = this.formEditar.get("FcEndLongNum").value;
    this.endereco.tipo_end = "L"
    this.getAddress(this.latitude, this.longitude, "city");
    console.log("teste");
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.formEditar.get("FcEndLatNum").setValue(this.latitude);
    this.formEditar.get("FcEndLongNum").setValue(this.longitude);
    this.getAddress(this.latitude, this.longitude, "city");
  }

  getAddress(latitude, longitude, tipo) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
            this.DadosEndGo = [];
            this.DadosEndGo = results[0].address_components;
            //this.DadosEndGo = results[0].address_components
            // console.log('this.size',this.DadosEndGo.size)
            // console.log('this.size results' , results[0].address_components.size)
            if (tipo === "city") {
              this.DadosEndGo = results[0].address_components;
              // console.log('this.size > 0',this.DadosEndGo)
              // console.log('DadosEndGo', this.DadosEndGo);
              this.DadosEndGo.filter((res: any) => {
                console.log("this", this.DadosEndGo);
                if (res.types.indexOf("postal_code") > -1) {
                  // console.log('postal_code', res.long_name);
                  this.formEditar.get("FcEndCep").setValue(res.long_name);
                  this.endereco.cep = res.long_name;
                }
                if (res.types.indexOf("administrative_area_level_2") > -1) {
                  console.log("long_name CIDADE", res.long_name);
                  this.formEditar.get("FcEndCidade").setValue(res.long_name);
                  this.DataCity.filter((option) => {
                    if (
                      option.Cidade.toUpperCase().indexOf(
                        res.long_name.toUpperCase()
                      ) > -1
                    ) {
                      this.endereco.municipio = option.id;
                    }
                  });
                }
                if (res.types.indexOf("street_number") > -1) {
                  // console.log('street_number', res.long_name);
                  this.formEditar.get("FcEndNumero").setValue(res.long_name);
                  this.endereco.numero = res.long_name;
                }
                if (res.types.indexOf("sublocality") > -1) {
                  // console.log('sublocality', res.long_name);
                  this.formEditar.get("FcEndBairro").setValue(res.long_name);
                  this.endereco.bairro = res.long_name;
                }

                if (res.types.indexOf("route") > -1) {
                  // console.log('route', res.long_name)
                  this.formEditar
                    .get("FcEndLogradouro")
                    .setValue(res.long_name);
                  this.endereco.logradouro = res.long_name;
                }
                this.ChechEditEnd = true;
              });
            }
            if (tipo === "no") {
              this.endereco.latitude == latitude;
              this.endereco.longitude == longitude;
              this.endereco.cep == "";
            }

            // console.log('this',this.DadosEndGo)
          } else {
            window.alert("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }
    );
  }

  changeValue(value, name) {
    if (name === "ckeckEnd") {
      this.checkLat = false;
      this.ckeckEnd = true;
      this.ChechEditEnd = false;
      this.setDisableEnd();
      // this.formEditar.get('FcEndLat').disable();
      // this.formEditar.get('FcEndLong').enable();
      this.formEditar.get("FcCidade").setValue("");
      this.formEditar.get("FcEndLat").setValue("");
      this.formEditar.get("FcEndCidade").setValue("");
      this.formEditar.get("FcEndLogradouro").setValue("");
      this.formEditar.get("FcEndBairro").setValue("");
      this.formEditar.get("FcEndCep").setValue("");
      this.formEditar.get("FcEndNumero").setValue("");
      this.formEditar.get("FcEndComplemento").setValue("");
    }
    if (name === "checkLat") {
      const cep = this.formEditar.get("FcEndCep").value;
      // console.log('cep', cep)
      if (this.formEditar.get("FcEndCep").value !== "") {
        this.formEditar.get("FcEndLat").setValue("");
        this.formEditar.get("FcEndCidade").setValue("");
        this.formEditar.get("FcEndLogradouro").setValue("");
        this.formEditar.get("FcEndBairro").setValue("");
        this.formEditar.get("FcEndCep").setValue("");
        this.formEditar.get("FcEndNumero").setValue("");
        this.formEditar.get("FcEndComplemento").setValue("");
      }
      this.checkLat = true;
      this.ChechEditEnd = false;
      this.formEditar.controls.FcCidade.setValue("");
      this.setDisableEnd();
    }
    if (name === "checkDanoS") {
      this.checkDanoS = true;
    }
    if (name === "checkFcCompS") {
      if (this.checkFcCompS) {
        this.checkFcCompS = false;
      } else {
        this.checkFcCompS = true;
      }
    } //
    if (name === "checkFcCompJp") {
      if (this.checkFcCompJp) {
        this.checkFcCompJp = false;
      } else {
        this.checkFcCompJp = true;
      }
    }
  }

  /* Filtros de Promotorias / Campos e Serviços */
  public _filter(value: string): Solicitante[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    let promo = "";

    this.promotorias.filter((option) => {
      if (
        option.nome
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
      ) {
        promo = option.id;
      }
    });
    this.form.solicitante = promo;
    return this.promotorias.filter(
      (option) =>
        option.nome
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }

  public _filterITS(value: string): Lista[] {
    const filterValue = value.toUpperCase();
    return this.lista.filter(
      (option) =>
        option.itcn_NR_INFOTECNICA.toUpperCase().indexOf(filterValue) > -1
    );
  }
  public _filterTecnicos(value: string): any[] {
    const filterValue = value.toUpperCase();
    return this.tecnicos.filter(
      (option) => option.nome.toUpperCase().indexOf(filterValue) > -1
    );
  }
  public _filterCpo2(value: string): CPO1[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.cpo2.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterSrvTec(value: string): Servicos[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.servicos.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterCpo1(value: string): any[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.cpo1.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterUnidades(value: string): any[] {
    const filterValue = value.toUpperCase();
    return this.DataSaude.filter(
      (option) => option.no_fantasia.toUpperCase().indexOf(filterValue) > -1
    );
  }
  public getCpo(nucleo) {
    /*
    console.log("SELECT MULT", this.formEditar.get("FCnucleo").value)
    let ServiTec = this.formEditar.get("FCnucleo").value;



    this.apiService.getCpo1Mult(nucleo).subscribe((res: CPO1[]) => {
      this.cpo1 = res;
      console.log("Cpos 1", res)
    });

    ServiTec.map((nucleo: any) => {
      if (nucleo === 6 || nucleo === 7 || nucleo === 3 || nucleo === 4) {
        this.CheckNuga = true;
      } else {
        this.CheckNuga = false;
      }
      if (nucleo === 2 || nucleo === 1) {
        this.CheckNugaSaude = true;
      }
    })


    if (nucleo === 6 || nucleo === 7 || nucleo === 3 || nucleo === 4) {
      this.CheckNuga = true;
    }
    if (nucleo === 2) {
      this.CheckNugaSaude = true;
    }
    */

  }
  getCPFCNPJ(cpf) {
    const cnpjv = cpf.replace(/[^\d]+/g, "");

    console.log("CNOS", cnpjv.length);
    if (cnpjv.length >= 11) {
      console.log("CNPJ > 11", cnpjv);
      this.apiService.getCPF(cnpjv).subscribe((res: any[]) => {
        res.map((res2: any) => {
          this.formEditar.get("FcNmInves").setValue(res2.nome);
          console.log("CNPJ NOME", res2.nome);
          return res2.nome;
        });
      });
    } else {
      return null;
    }
  }
  public getCpo2(e) {
    const nucleo = this.formEditar.get("FCnucleo").value;

    if (nucleo === 6 || nucleo === 7 || nucleo === 3 || nucleo === 4) {
      this.CheckNuga = true;
    }
    if (nucleo === 2) {
      this.CheckNugaSaude = true;
    }
    this.contrato = new RegExp(/^Contrat*/);
    // console.log('this.contrato', this.contrato);
    console.log("this.CheckNuga", this.CheckNuga);
    console.log("this.contrato", this.contrato.test(e.option.value));
    if (this.contrato.test(e.option.value)) {
      this.checkContrato = true;
      document.getElementById("demo").setAttribute("class", "collapse show");
      this.form.nvl_proced = null;
    } else {
      document.getElementById("demo").setAttribute("class", "collapse");
      this.checkContrato = false;
      this.formEditar.get("FcNvl_dano").setValidators(Validators.required);
    }
    const nugadk = this.formEditar.get("FCnucleo").value;
    let cpo = "";
    this.cpo1.filter((res) => {
      if (res.ds.indexOf(e.option.value) > -1) {
        cpo = res.dk;
      }
    });
    if (cpo !== "") {
      this.selectCpo1 = true;
    }
    this.form.campo_1 = cpo;
    this.apiService.getCpo2(nugadk, cpo).subscribe((res: CPO2[]) => {
      this.cpo2 = res;
    });
  }
  public getTecnico() {
    this.apiService.getTecnicos().subscribe((res: Tecnicos[]) => {
      this.tecnicos = res;
      //// console.log('', res);
    });
  }
  public getServTec(e) {
    const nucleo = this.formEditar.get("FCnucleo").value;
    let cpo2 = "";
    this.cpo2.filter((res) => {
      if (res.ds.indexOf(e.option.value) > -1) {
        cpo2 = res.dk;
      }
    });

    this.form.campo_2 = cpo2;
    console.log("CPO2", this.form.campo_2);
    this.apiService.getServTecMult(nucleo).subscribe((res: Servicos[]) => {
      this.servicos = res;
    });
    console.log("serv", this.servicos);
  }
  getEndUnidade(value) {
    console.log(value);
    this.DataSaude.map((res: any) => {
      // console.log(value);
      if (res.no_fantasia === value) {
        this.latitude = res.latitude;
        this.longitude = res.longitude;
        // console.log('Res', this.latitude, this.longitude);

        this.getAddress(this.latitude, this.longitude, "city");
        this.endereco.tipo_end = "T"
        // console.log('DadosEndGo 1', this.DadosEndGo);
      }

      // this.getAddress(this.latitude, this.longitude);
    });

    /*
        this.formEditar.get('FcEndNumero').setValue(res.Numero);

    */
  }
  setDisableEnd() {
    if (this.ChechEditEnd) {
      this.ckeckEnd = true;
      this.formEditar.get("FcEndCidade").disable();
      this.formEditar.get("FcEndLogradouro").disable();
      this.formEditar.get("FcEndBairro").disable();
      this.formEditar.get("FcEndCep").disable();
      this.formEditar.get("FcEndNumero").disable();
      this.formEditar.get("FcEndComplemento").disable();
      // FcEndComplemento
    } else {
      this.formEditar.get("FcEndCidade").enable();
      this.formEditar.get("FcEndLogradouro").enable();
      this.formEditar.get("FcEndBairro").enable();
      this.formEditar.get("FcEndCep").enable();
      this.formEditar.get("FcEndNumero").enable();
      this.formEditar.get("FcEndComplemento").enable();
    }
  }

  getEndCity(id) {
    this.DataCity.map((res: any) => {
      if (res.id === id) {

        // console.log(res);
        this.formEditar.get('FcEndCidade').setValue(res.Cidade.toUpperCase());
        this.formEditar.get('FcEndLogradouro').setValue(res.Logradouro.toUpperCase());
        this.formEditar.get('FcEndBairro').setValue(res.Bairro.toUpperCase());
        this.formEditar.get('FcEndCep').setValue(res.CEP.toUpperCase());
        this.formEditar.get('FcEndNumero').setValue(res.Numero);

        this.endereco.logradouro = res.Logradouro.toUpperCase();
        this.endereco.numero = res.Numero;
        this.endereco.complemento = '';
        this.endereco.bairro = res.Bairro.toUpperCase();
        this.endereco.municipio = res.id;
        this.endereco.cep = res.CEP.toUpperCase();
        this.endereco.tipo_end = "T"

        this.ChechEditEnd = false;
        this.ckeckEnd = true;
        this.formEditar.get('FcEndRadio').setValue('M')

      }
      if (id === undefined) {
        this.changeValue(this.ckeckEnd, 'ckeckEnd');
      }
    });

    //this.setDisableEnd()
  }
  public getCEP(cep) {
    // console.log(cep);
    this.formEditar.get("FcEndRadio").setValue("E");
    cep = cep.replace(/\D/g, "");
    if (cep !== 123 && cep !== "") {
      if (cep < 20000000 || cep > 29999999) {
        const dt1 = {
          titulo: "CEP INVÁLIDO",
          status: 441,
          statusText: "CEP inválido ou fora do Estado do Rio de Janeiro.",
        };
        this.msgdialogService.openDialog(dt1);
        this.formEditar.get("FcEndLat").setValue("");
        this.formEditar.get("FcEndLat").disable();
        this.formEditar.get("FcEndCidade").setValue("");
        this.formEditar.get("FcEndCidade").disable();
        this.formEditar.get("FcEndLogradouro").setValue("");
        this.formEditar.get("FcEndLogradouro").disable();
        this.formEditar.get("FcEndBairro").setValue("");
        this.formEditar.get("FcEndBairro").disable();
        this.formEditar.get("FcEndNumero").setValue("");
        this.formEditar.get("FcEndNumero").disable();
        this.formEditar.get("FcEndComplemento").setValue("");
        this.formEditar.get("FcEndComplemento").disable();
        return false;
      }

      this.apiService.getCep(cep).subscribe((res: any) => {
        const logr = res.logradouro;
        // console.log('cep', res);
        this.formEditar.get("FcCidade").setValue("");
        this.formEditar.get("FcCidade").enable();
        this.formEditar.get("FcEndLat").setValue("");
        this.formEditar.get("FcEndLat").enable();
        this.formEditar.get("FcEndCidade").setValue("");
        this.formEditar.get("FcEndCidade").enable();
        this.formEditar.get("FcEndLogradouro").setValue("");
        this.formEditar.get("FcEndLogradouro").enable();
        this.formEditar.get("FcEndBairro").setValue("");
        this.formEditar.get("FcEndBairro").enable();
        this.formEditar.get("FcEndNumero").setValue("");
        this.formEditar.get("FcEndNumero").enable();
        this.formEditar.get("FcEndComplemento").setValue("");
        this.formEditar.get("FcEndComplemento").enable();

        if (res.localidade !== "") {
          this.formEditar
            .get("FcEndCidade")
            .setValue(res.localidade.toUpperCase());
          this.formEditar.get("FcEndCidade").disable();
        }
        if (res.logradouro !== "") {
          this.formEditar
            .get("FcEndLogradouro")
            .setValue(res.logradouro.toUpperCase());
          this.formEditar.get("FcEndLogradouro").disable();
        }
        if (res.bairro !== "") {
          this.formEditar.get("FcEndBairro").setValue(res.bairro.toUpperCase());
          this.formEditar.get("FcEndBairro").disable();
        }
        this.formEditar.get("FcEndCep").setValue(res.cep.toUpperCase());

        // console.log('Res CEP', res);
        let cidadk;
        this.DataCity.filter((option) => {
          if (option.Cidade.indexOf(res.localidade) > -1) {
            cidadk = option.id;
          }
        });
        if (cep === "22231901") {
          this.formEditar.get("FcEndNumero").setValue("S/N");
          this.formEditar.get("FcEndNumero").disable();
        }
        this.endereco.logradouro = res.logradouro.toUpperCase();
        this.endereco.numero = "";
        this.endereco.complemento = "";
        this.endereco.bairro = res.bairro.toUpperCase();
        this.endereco.municipio = cidadk;
        this.endereco.cep = res.cep.toUpperCase();
        this.endereco.tipo_end = "E"

      });
      this.ChechEditEnd = true;
      this.setDisableEnd();
    } else {
      this.ChechEditEnd = false;
      this.setDisableEnd();
      // this.formEditar.get('FcEndLat').disable();
      // this.formEditar.get('FcEndLong').enable();
      this.formEditar.get("FcCidade").setValue("");
      this.formEditar.get("FcEndLat").setValue("");
      this.formEditar.get("FcEndLat").disable();
      this.formEditar.get("FcEndCidade").setValue("");
      this.formEditar.get("FcEndCidade").disable();
      this.formEditar.get("FcEndLogradouro").setValue("");
      this.formEditar.get("FcEndLogradouro").disable();
      this.formEditar.get("FcEndBairro").setValue("");
      this.formEditar.get("FcEndBairro").disable();
      this.formEditar.get("FcEndCep").setValue("");
      this.formEditar.get("FcEndNumero").setValue("");
      this.formEditar.get("FcEndNumero").disable();
      this.formEditar.get("FcEndComplemento").setValue("");
      this.formEditar.get("FcEndComplemento").disable(); //this.ChechEditEnd = true;
      console.log("END", this.endereco);
      this.setDisableEnd();
    }
    console.log("END", this.endereco);
  }
  public validateCpo1(e, valor) {
    this.selectCpo1 = null;
    let cpo1 = this.formEditar.get("FCcpo1").value; // = valor;
    this.formEditar.get("FCcpo2").setValue("");
    cpo1 = cpo1.trim();
    let validaCpo1 = "";
    if (cpo1.length > 0) {
      if (valor === "select" || "change") {
        this.cpo1.map((res) => {
          if (res.ds === cpo1) {
            validaCpo1 = res.ds.toUpperCase();
            this.selectCpo1 = true;
            this.form.campo_1 = res.dk;
            return;
          }
        });
        if (this.selectCpo1 !== true) {
          this.selectCpo1 = false;
        }
      }
    }
  }

  public validateCpo2(e, valor) {
    this.selectCpo2 = null;
    let cpo = this.formEditar.get("FCcpo2").value; // = valor;
    cpo = cpo.trim();
    // console.log('antes if', cpo)
    if (cpo.length > 0) {
      if (valor === "select" || "change") {
        this.cpo2.map((res) => {
          if (res.ds === cpo) {
            this.form.campo_2 = res.dk = res.dk;
            this.selectCpo2 = true;
          }
        });
      }
      if (this.selectCpo2 !== true) {
        this.selectCpo2 = false;
      }
    }
  }

  public validateServTec(e, valor) {
    this.selectSrv = null;
    let srv = this.formEditar.get("FCServTec").value; // = valor;
    srv = srv.trim();
    if (srv.length > 0) {
      if (valor === "select" || "change") {
        this.servicos.map((res) => {
          if (res.ds === srv) {
            this.selectSrv = true;
          }
        });
      }
      if (this.selectSrv !== true) {
        this.selectSrv = false;
      }
    }
  }
  selectionMade(event: Event) {
    event.stopPropagation();
    this.trigger.openPanel();
  }

  // Valida se a promotiroa preenchida é valida
  public validateSolicitante(e, valor) {
    this.selectSoli = null;
    let promo = this.formEditar.get("Fcsolicitante").value; // = valor;
    promo = promo.trim();
    if (promo.length > 0) {
      if (valor === "select" || "change") {
        this.promotorias.map((res) => {
          if (res.nome === promo) {
            this.selectSoli = true;
            this.form.solicitante = res.id;
            return;
          }
        });
      }
      if (this.selectSoli !== true) {
        this.selectSoli = false;
      }
    }
  }
  public getSeiPromo(numSei) {
    this.apiService.getSolict(numSei).subscribe((res: any) => {
      // console.log('NumMprj', res);
      const nome = res.nome;
      const mprj = res.NumMprj;
      // console.log('NumMprj', res.NumMprj);
      // console.log('validaMprj', this.validaMprj);
      if (res.resultMPRJ !== "Ok") {
        this.formEditar.get("FCmprj").setValue("");
        this.validaMprj = false;
      }

      const verifyMprj = res.resultMPRJ;
      const mrpjFormat = mprj.replace(/(\d{4})(\d)/, "$1.$2");
      // console.log('mrpjFormat', mrpjFormat);
      this.formEditar.get("Fcsolicitante").setValue(nome); // = valor;
      this.form.solicitante = res.id;
      if (verifyMprj === "Ok") {
        this.formEditar.get("FCmprj").setValue(mrpjFormat);
        this.form.numero_mprj = mprj;
        this.validaMprj = true;
        // this.getMPRJValidado(mprj);
        // this.getMPrjDupli(mprj);
      }
      this.setNuga();
    });
  }
  mprj_format(mprj) {
    return mprj.replace(/(\d{4})(\d)/, "$1.$2");
  }
  getMPRJValidado(obj) {
    this.validaMprj = false;
    this.apiService.getValidaMPRJ(obj).subscribe((res: any) => {
      if (res.cod === 200) {
        this.validaMprj = true;
      }
    });
  }
  getMPrjDupli(obj) {
    this.validaMprj = false;
    this.apiService.getMprjDupli(obj).subscribe((res: any) => {
      if (res.cod === 200) {
        this.validaMprj = true;
      }
    });
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  helpOpen(e) {
    let id = "@SEI";

    this.httpClient.get("assets/mocks/help.json").subscribe((data: any[]) => {
      data.map((res) => {
        if (res.id === id) {
          // this.helpservice.openDialog(res);
        }
      });
    });

    // this.helpservice.openDialog(dt1);
  }

  setProgess(e, valor) {
    // console.log('Valor', valor);
    // console.log('Stepper', this.stepper);
    //

    let steperPrev = false;
    this.contrato = new RegExp(/^Contrat*/);

    const frist10 = this.formEditar.get("FCnumeroSei").value;
    const frist11 = this.formEditar.get("FCmprj").value;
    const frist12 = this.formEditar.get("Fcsolicitante").value;
    const frist13 = this.formEditar.get("FCnucleo").value;
    const step21 = this.formEditar.get("FCcpo1").value;
    const step22 = this.formEditar.get("FCcpo2").value;
    const step23 = this.dataSourceTecnico.data.length;
    const step24 = this.dataSource.data.length;

    const step31 = this.formEditar.get("FcEndCep").value;
    const step32 = this.formEditar.get("FcEndLatNum").value;
    const step33 = this.formEditar.get("FcEndLongNum").value;

    const nucleo = this.formEditar.get("FCnucleo").value;
    if (nucleo === 6 || nucleo === 7 || nucleo === 3 || nucleo === 4) {
      this.CheckNuga = true;
    }
    if (nucleo === 2) {
      this.CheckNugaSaude = true;
    }
    // console.log('e.type', e.type)
    /*if (e.type === 'click') {
      this.KeyPressEnter = false;
    }*/
    /*if (this.KeyPressEnter === true) {
      this.stepper.previous();
      this.stepper.selected.completed = false;

    }*/
    // VOLTAR AO PRIMEIRO PASSO
    if (valor === 29) {
      this.stepper.selectedIndex = 0;
    }
    // console.log('Stepper', this.stepper);

    // VALIDAR E AVANÇAR AO SEGUNDO PASSO
    if (valor === 30) {
      // this.validateSolicitante(this.stepper, 'blur')
      if (frist11 === "" || frist12 === "" || frist13 === "") {
        // console.log(frist10, frist11, frist12, frist13);
        const dt1 = {
          titulo: "OPS",
          status: 102,
          statusText:
            "Alguns dos campos não foram peenchidos ou não estão corretos! Por favor verifique os campos em vermelho.",
        };
        this.msgdialogService.openDialog(dt1);
        valor = 0;
        steperPrev = true;
        this.stepper.selectedIndex = 0;
        this.stepper.selected.completed = false;
        return;
      } else {
        this.stepper.selected.completed = true;
        this.stepper.selectedIndex = 1;
        this.stepper.selected.completed = false;
      }
    }

    //VOLTAR AO SEGUNDO PASSO
    if (valor === 59) {
      this.stepper.selectedIndex = 1;
    }

    //VALIDAR E AVANÇAR AO TERCEIRO PASSO
    if (valor === 60) {
      if (
        step21 === "" ||
        step22 === "" ||

        step24 <= 0 ||
        this.selectCpo1 === false ||
        this.selectCpo2 === false
      ) {
        // console.log(step21, step22, step23);
        const dt1 = {
          titulo: "OPS",
          status: 201,
          statusText:
            "Alguns dos campos não foram preenchidos ou não estão corretos! Por favor verifique os campos em vermelho.",
        };
        this.msgdialogService.openDialog(dt1);
        valor = 30;
        this.stepper.selectedIndex = 1;
        this.stepper.selected.completed = false;
        return;
      } else {
        this.stepper.selected.completed = true;
        this.stepper.selectedIndex = 2;
        this.stepper.selected.completed = false;
      }
    }

    //VOLTAR AO TERCEIRO PASSO
    if (valor === 89) {
      this.stepper.selectedIndex = 1;
    }

    //VALIDAR E AVANÇAR AO QUARTO PASSO
    if (valor === 90) {
      ///
      if (
        this.form.dano_erario === "S" &&
        this.formEditar.controls.FcDano.value === ""
      ) {
        const dt3 = {
          titulo: "OPS",
          status: "Dano",
          statusText: "O dano esta marcado como Sim mais seu valor e 0,00",
        };
        this.msgdialogService.openDialog(dt3);
        valor = 60;
        this.stepper.selected.completed = false;
        this.stepper.selectedIndex = 2;
        return;
      }
      if (step31 === "" && step32 === "") {
        // console.log('step31', step31.toString());
        // console.log('step32', step32.toString());
        // console.log('step33', step33);
        const dt3 = {
          titulo: "OPS",
          status: "Endereço",
          statusText:
            "É obrigatório o preenchimento de um endereço ou Lat/Long.",
        };
        this.msgdialogService.openDialog(dt3);
        valor = 60;
        this.stepper.selected.completed = false;
        this.stepper.selectedIndex = 2;
        return;
      } else {
        this.stepper.selected.completed = true;
        this.stepper.selectedIndex = 3;
        this.stepper.selected.completed = false;
      }

      if (this.contrato.test(step21)) {
        console.log("Valor Dano", this.formEditar.controls.FcDano.value);
        console.log("Dano Zero", this.formEditar.controls.Fcdzero.value);
        this.checkContrato = true;
        if (this.formEditar.get("FcNvl_dano").value === "") {
          const dt1 = {
            titulo: "OPS",
            status: 443,
            statusText:
              "Para o campo 1 igual a contratos é obrigatório o preenchimento do nivel do dano (municipal ou estadual).",
          };
          this.msgdialogService.openDialog(dt1);
          valor = 60;
          this.stepper.selected.completed = false;
          this.stepper.selectedIndex = 2;
          return;
        }
        if (this.formEditar.get("FcTpContrato").value === "") {
          const dt1 = {
            titulo: "OPS",
            status: 443,
            statusText:
              "Para o campo 1 igual a contratos é obrigatório o preenchimento objeto de contrato (insumo, obra ou serviço).",
          };
          steperPrev = true;
          this.msgdialogService.openDialog(dt1);
          valor = 60;
          this.stepper.selectedIndex = 2;
          this.stepper.selected.completed = false;
        }
        if (this.dataSourceCPF.data.length <= 0) {
          const dt1 = {
            titulo: "OPS",
            status: 443,
            statusText:
              "Para o Campo 1 igual a contratos é obrigatorio o preenchimento de uma empresa.",
          };
          steperPrev = true;
          this.msgdialogService.openDialog(dt1);
          valor = 60;
          this.stepper.selectedIndex = 2;
          this.stepper.selected.completed = false;
          return;
        }
      }
    }
    this.progress = valor;
  }

  removeSelectedRows(evento: string) {
    if (evento === "ServTec") {
      this.selection.selected.forEach((item) => {
        const index: number = this.dataTable.findIndex((d) => d === item);
        this.dataTable.splice(index, 1);
        this.servitec = [];
        this.OptecServ = [];
        if (item.opiniao === "Não se aplica ao serviço técnico em questão") {
          this.formEditar.get("FcCompTec").setValue(" ");
          this.CompOp = true;
        }
      });
      this.dataTable.forEach((item) => {
        let opt;
        let srv;
        this.servicos.filter((option) => {
          if (option.ds.indexOf(item.servico) > -1) {
            this.servitec.push({ id: option.dk });
            srv = option.dk;
          }
        });
        this.optec.filter((option) => {
          if (option.ds.indexOf(item.opiniao) > -1) {
            this.OptecServ.push({ id_servico: srv, id_opiniao: option.dk });
          }
        });
      });
      this.dataSource = new MatTableDataSource<Element>(this.dataTable);
      this.selection = new SelectionModel<Element>(true, []);
    }

    if (evento === "CNPJ") {
      this.cpfs_cnpjs = []
      let cnpj_remove = "";
      this.selectionCPF.selected.forEach((item) => {
        const index: number = this.dataTableCPF.findIndex((d) => d === item);
        this.dataTableCPF.splice(index, 1);
        cnpj_remove = item.cpf_cnpj
      });
      let val = 0;

      this.dataTableCPF.map((d) => {
        val = val + d.val_dano;
      });

      this.totDano = val;

      if (this.totDano > 0) {
        // console.log('val if', val);
        this.checkDanoS = true;
      } else if (this.totDano <= 0) {
        this.checkDanoS = false;
      }
      /*
      this.cpfs_cnpjs.push({
        cpf_cnpj: cnpj.replace(/\D/g, ""),
        nm_contratada: nome,
        num_contratos: totContratosAna,
        num_contratos_dano: totContratosCd,
        val_dano: valorDanoContra,
      });
      */
      this.dataTableCPF.forEach((cnpj) => {
         this.cpfs_cnpjs.push({
          cpf_cnpj: cnpj.cpf_cnpj,
          nm_contratada: cnpj.nm_contratada,
          num_contratos: cnpj.num_contratos,
          num_contratos_dano: cnpj.num_contratos_dano,
          val_dano: cnpj.val_dano,

         })
      });
      console.log('Data CPFS',this.dataTableCPF)
      console.log('CPFS Form',this.form.cpfs_cnpjs)
      console.log('CPFS Array',this.cpfs_cnpjs)
      this.formEditar.get("FcDano").setValue(this.totDano);
      this.dataSourceCPF = new MatTableDataSource<Element2>(this.dataTableCPF);
      this.selectionCPF = new SelectionModel<Element2>(true, []);
    }
    if (evento === "Tecnico") {
      this.selectionTecnico.selected.forEach((item) => {
        const index: number = this.dataTableTecnicos.findIndex(
          (d) => d === item
        );
        // console.log('TESTE 1', item.tecnico);

        if (item.tecnico === this.usuario) {
          return;
        }

        this.dataTableTecnicos.splice(index, 1);
        this.dadosTec.slice(index, 1);
      });
      // console.log('Tec', this.tecnico)
      this.tecnico = [];
      this.dataTableTecnicos.forEach((item) => {
        this.tecnicos.filter((option) => {
          if (option.nome.indexOf(item.tecnico) > -1) {
            this.tecnico.push({ id: option.matric });
          }
        });
      });
      // console.log('dataTableTecnicos', this.dataTableTecnicos.length )
      this.dataSourceTecnico = new MatTableDataSource<ElementTec>(
        this.dataTableTecnicos
      );
      this.selectionTecnico = new SelectionModel<ElementTec>(true, []);
      // console.log('Remove')
    }
  }

  addRows(servico, opniao) {
    const Servico = this.formEditar.get("FCServTec").value;
    const Opniao = this.formEditar.get("FCopTec").value;
    const Tecnico = this.formEditar.get("FcTec").value;
    let valid = true;
    let op;
    let serv;
    let tec
    if (Servico === "" || Opniao === "") {
      const dt1 = {
        titulo: "Erro serviço técnico",
        status: 439,
        statusText:
          "É obrigatorio selecionar um serviço técnico e uma opnião técnica.",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }
    if (this.selectSrv === false) {
      const dt1 = {
        titulo: "Erro serviço técnico",
        status: 440,
        statusText:
          "O serviço técnico selecionado não foi encontrado na base do ProData.",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }

    this.optec.filter((option) => {
      if (option.ds.indexOf(Opniao) > -1) {
        op = option.dk;
      }
    });
    this.tecnicos.filter((option) => {
      if (option.nome.indexOf(Tecnico) > -1) {
        tec = option.matric;
      }
    })
    if (op === 5) {
      this.CompOp = true;
    }
    console.log('this.servicos', this.servicos)
    this.servicos.filter((option) => {
      if (option.ds.indexOf(Servico) > -1) {
        serv = option.dk;
      }
    });
    if (this.OptecServ.length > 0) {
      this.OptecServ.map((opsrv) => {
        if (opsrv.id_servico === serv && opsrv.cdmatricula === tec) {
          valid = false;
          const dt1 = {
            titulo: " Serviço já Cadastrado",
            status: 223,
            statusText:
              "O Serviço Técnico: " + Servico + " já  encontra-se na lista do Técnico " + Tecnico,
          };
          this.msgdialogService.openDialog(dt1);

          return;
        }
      });
    }
    if (valid && this.itcn_dt != 'true') {
      console.log('serviço', serv)
      this.OptecServ.push({ id_servico: serv, id_opiniao: op, cdmatricula: tec });
      let intserv = 0
      if (this.servitec.length > 0) {
        this.servitec.filter((servmap) => {
          if (serv == servmap.id) {
            intserv = serv + 1
          }
          console.log('serviço igual', serv)
        })
      } else {
        this.servitec.push({ id: serv });
        intserv = 1
      }
      if (intserv == 0) {
        console.log('Cadastrou Serviço ', serv)
        this.servitec.push({ id: serv });
      }
      console.log('Serviços', this.servitec)

      let int = 0;
      if (this.tecnico.length > 0) {
        this.tecnico.filter((option) => {
          if (option.id == tec) {
            int = option.id + 1;
          }
          console.log('Cadastro ', this.tecnico)
        })
      } else {
        this.tecnico.push({ id: tec });
        int = 1;
      }
      if (int == 0) {
        console.log('Cadastrou', tec)
        this.tecnico.push({ id: tec });
      }
      console.log('Técnicos', this.tecnico)
      /*
      this.form.tecnicos = this.tecnico;
    this.form.servicos_tecnicos = this.servitec;
      */

      console.log('Serviços', this.servitec)
      this.form.opiniao_tecnica = this.OptecServ;
      console.log('Opiniao', this.form.opiniao_tecnica)

      this.dados.push({
        position: this.dataTable.length + 1,
        servico: Servico,
        opiniao: Opniao,
        tecnico: Tecnico,
      });
      this.dataTable = this.dados;
      this.dataSource = new MatTableDataSource<Element>(this.dataTable);
    }

    if (this.itcn_dt == 'true') {
      if (serv != 400) {
        const dt1 = {
          titulo: " Serviço inválido",
          status: 223,
          statusText:
            "Na Diretriz tecnica só é possivel cadastrar (Elaboração de diretriz técnica) ",
        };
        this.msgdialogService.openDialog(dt1);
      }


      //this.OptecServ.push({ id_servico: 400, id_opiniao: 5, itst_cdmatricula: tec });
      //this.servitec.push({ id: 400 });

      this.form.opiniao_tecnica = this.OptecServ;
      //console.log('Opiniao', this.form.opiniao_tecnica)

      this.dados.push({
        position: this.dataTable.length + 1,
        servico: Servico,
        opiniao: Opniao,
        tecnico: Tecnico,
      });
      this.dataTable = this.dados;
      this.dataSource = new MatTableDataSource<Element>(this.dataTable);

    }
    this.formEditar.get("FCServTec").setValue("");
    this.formEditar.get("FCopTec").setValue("");
    this.formEditar.get("FcTec").setValue("");
  }

  /*
  addRows(servico, opniao) {
    const Serviço = this.formEditar.get("FCServTec").value;
    const Opniao = this.formEditar.get("FCopTec").value;
    let valid = true;
    let op;
    let serv;
    if (Serviço === "" || Opniao === "") {
      const dt1 = {
        titulo: "Erro serviço técnico",
        status: 439,
        statusText:
          "É obrigatorio selecionar um serviço técnico e uma opnião técnica.",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }
    if (this.selectSrv === false) {
      const dt1 = {
        titulo: "Erro serviço técnico",
        status: 440,
        statusText:
          "O serviço técnico selecionado não foi encontrado na base do ProData.",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }

    this.optec.filter((option) => {
      if (option.ds.indexOf(Opniao) > -1) {
        op = option.dk;
      }
    });


    this.CompOp = true;


    this.servicos.filter((option) => {
      if (option.ds.indexOf(Serviço) > -1) {
        serv = option.dk;
      }
    });
    if (this.OptecServ.length > 0) {
      this.OptecServ.map((opsrv) => {
        if (opsrv.id_servico === serv) {
          valid = false;
          const dt1 = {
            titulo: " Serviço já Cadastrado",
            status: 223,
            statusText:
              "O Serviço Tecnico: " + Serviço + " já  encontra-se na lista",
          };
          this.msgdialogService.openDialog(dt1);

          return;
        }
      });
    }
    if (valid) {
      // console.log('serviço', serv)
      this.OptecServ.push({ id_servico: serv, id_opiniao: op });
      this.servitec.push({ id: serv });
      this.form.opiniao_tecnica = this.OptecServ;

      this.dados.push({
        position: this.dataTable.length + 1,
        servico: Serviço,
        opiniao: Opniao,
      });
      this.dataTable = this.dados;
      this.dataSource = new MatTableDataSource<Element>(this.dataTable);
    }
    this.formEditar.get("FCServTec").setValue("");
    this.formEditar.get("FCopTec").setValue("");
  }
  */

  AddRowsCPF() {
    const cnpj = this.formEditar.get("FcCnpj").value;
    const nome = this.formEditar.get("FcNmInves").value;
    let totContratosAna = this.formEditar.get("FctotContra").value;
    let totContratosCd = this.formEditar.get("FctotContraCd").value;
    let valorDanoContra = this.formEditar.get("FcValDano").value;
    let valid = true;
    if (this.checkContrato === true) {
      if (
        totContratosAna === "" ||
        totContratosCd === "" ||
        totContratosAna === 0
      ) {
        valid = false;
        const dt1 = {
          titulo: "Nº de contratos",
          status: 338,
          statusText: "É necessário inserir o total de contratos analisados.",
        };
        this.msgdialogService.openDialog(dt1);
        return;
      }
      if (totContratosCd > totContratosAna) {
        valid = false;
        const dt1 = {
          titulo: "Nº de contratos",
          status: 339,
          statusText:
            "O total de contratos com dano não pode ser maior que o total de contratos analisados.",
        };
        this.msgdialogService.openDialog(dt1);
        return;
      }
      if (
        totContratosCd > 0 &&
        (valorDanoContra === 0 || valorDanoContra === "")
      ) {
        valid = false;
        const dt1 = {
          titulo: "Nº de contratos",
          status: 340,
          statusText:
            "Por existir contratos com dano o valor do dano não pode ser zerado.",
        };
        this.msgdialogService.openDialog(dt1);
        return;
      }
    }
    if (totContratosAna === "" || totContratosAna <= 0) {
      this.formEditar.get("FctotContra").setValue(0);
      totContratosAna = 0;
    }
    if (totContratosCd === "" || totContratosCd <= 0) {
      this.formEditar.get("FctotContraCd").setValue(0);
      totContratosCd = 0;
    }
    if (valorDanoContra === "" || valorDanoContra <= 0) {
      this.formEditar.get("FcValDano").setValue(0);
      valorDanoContra = 0;
    }
    if (cnpj === "" || nome === "" || nome === "Pesquisando...") {
      valid = false;
      const dt1 = {
        titulo: "Erro CNPJ",
        status: 341,
        statusText:
          "Aguarde a pesquisa pelo nome. \r\n É obrigatorio inserir o CPF/CNPJ e nome do investigado.",
      };
      this.msgdialogService.openDialog(dt1);
      return;
    }

    if (this.dadosCPF.length > 0) {
      this.dadosCPF.filter((res) => {
        if (res.cpf_cnpj === cnpj) {
          valid = false;
          const dt1 = {
            titulo: " CPF/CNPJ já Cadastrado",
            status: 323,
            statusText: "O CNPJ " + cnpj + " já  encontra-se na lista.",
          };
          this.msgdialogService.openDialog(dt1);
          return;
        }
      });
    }
    if (valid) {
      this.dadosCPF.push({
        position: this.dataTableCPF.length + 1,
        cpf_cnpj: cnpj,
        nm_contratada: nome,
        num_contratos: totContratosAna,
        num_contratos_dano: totContratosCd,
        val_dano: valorDanoContra,
      });
      this.dataTableCPF = this.dadosCPF;
      this.cpfs_cnpjs.push({
        cpf_cnpj: cnpj.replace(/\D/g, ""),
        nm_contratada: nome,
        num_contratos: totContratosAna,
        num_contratos_dano: totContratosCd,
        val_dano: valorDanoContra,
      });
      this.dataSourceCPF = new MatTableDataSource<Element2>(this.dataTableCPF);
      if (valorDanoContra > 0) {
        this.totDano = this.totDano + valorDanoContra;
        this.setDanos("Dano");
        this.checkDanoS = true;
        this.checkDanoAuto = true;
        this.formEditar.get("FcCheckDano");
        this.formEditar.get("FcCheckDano").disable();
        this.formEditar.get("FcDano").disable();
        this.form.dano_erario = "S";
      } else if (this.totDano <= 0) {
        this.setDanos("Dano");
        this.checkDanoS = false;
        this.checkDanoAuto = false;
        this.formEditar.get("FcCheckDano");
        this.formEditar.get("FcCheckDano").disable();
        this.formEditar.get("FcDano").disable();
        this.form.dano_erario = "N";
      }
      const valorDanoContraCurrency = formatCurrency(
        valorDanoContra,
        "pt_BR",
        "R$",
        "BRL"
      );
      const totDanoCurrency = formatCurrency(
        this.totDano,
        "pt_BR",
        "R$",
        "BRL"
      );
      // console.log(valorDanoContraCurrency)
      if (valorDanoContra > 0) {
        // const valorDanoContraCurrency = formatCurrency(valorDanoContra,'pt_BR', 'R$', 'BRL','2')
        const dt1 = {
          titulo: "Confirme o valor do dano:",
          status: "Aviso",
          statusText:
            "Valor do dano é " +
            valorDanoContraCurrency +
            "\r\n" +
            " e total de dano ao erário é " +
            totDanoCurrency,
        };
        this.msgdialogService.openDialog(dt1);
      }
    }
    this.formEditar.get("FcDano").setValue(this.totDano);
    this.formEditar.get("FcCnpj").setValue("");
    this.formEditar.get("FcNmInves").setValue("");
    this.formEditar.get("FctotContra").setValue("");
    this.formEditar.get("FctotContraCd").setValue("");
    this.formEditar.get("FcValDano").setValue("");
    this.form.valor_dano = this.formEditar.get("FcDano").value;
  }

  AddRowsTec(odj) {
    let nome = "";
    let matricula;

    if (odj === "Auto") {
      nome = this.usuario;
      this.tecnicos.filter((option) => {
        if (option.nome.indexOf(nome) > -1) {
          matricula = option.matric;
        }
      });
      this.tecnico.push({ id: matricula });
      this.dadosTec.push({
        position: this.dataTableTecnicos.length + 1,
        tecnico: nome,
      });
      this.dataTableTecnicos = this.dadosTec;
      this.dataSourceTecnico = new MatTableDataSource<ElementTec>(
        this.dataTableTecnicos
      );
      // this.formEditar.get('FcTec').setValue('');
    }
    nome = this.formEditar.get("FcTec").value;

    if (nome === "" && odj !== "Auto") {
      const dt1 = {
        titulo: "Erro Técnico",
        status: 439,
        statusText: "Selecione um Técnico",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }
    matricula = "";

    let valid = true;

    if (nome !== "" && odj === "get") {
      this.tecnicos.filter((option) => {
        if (option.nome.indexOf(nome) > -1) {
          matricula = option.matric;
        }
      });
      if (this.tecnico.length > 0) {
        this.tecnico.filter((matr) => {
          if (matr.id === matricula) {
            valid = false;
            const dt1 = {
              titulo: " Técnico já Cadastrado",
              status: 223,
              statusText: "O Técnico " + nome + " já  encontra-se na lista",
            };
            this.msgdialogService.openDialog(dt1);
            return;
          }
        });
      }
      if (valid) {
        this.tecnico.push({ id: matricula });
        this.dadosTec.push({
          position: this.dataTableTecnicos.length,
          tecnico: nome,
        });

        this.dataTableTecnicos = this.dadosTec;
        this.dataSourceTecnico = new MatTableDataSource<ElementTec>(
          this.dataTableTecnicos
        );
        this.formEditar.get("FcTec").setValue("");
      }
    }
  }

  NumMPRJ(v) {
    v = this.formEditar.get("FCmprj").value;

    v = v.replace(/\D/g, "");
    if (v.length <= 13) {
      // mprj
      v = v.replace(/(\d{4})(\d)/, "$1.$2");
      this.formEditar.get("FCmprj").setValue(v);
    }

    return v;
  }
  setDanos(objeto) {
    if (objeto === "Dano") {
      this.form.dano_erario = "S";
    }
    if (objeto === "InDano") {
      this.form.inAtualVal = "S";
    }
    if (objeto === "AtualValor") {
      this.form.inCompAnal = "S";
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }
  openBottomSheet(): void {
    // this._bottomSheet.open();
  }

  mascaraMutuario(o, f) {
    this.Vobj = o;
    this.vFun = f;

    setTimeout(this.cpfCnpj(f), 4);
  }
  execmascara() {
    this.Vobj.value = this.vFun(this.Vobj.value);
  }

  cpfCnpj(v) {
    v = this.formEditar.get("FcCnpj").value;
    const re = v;
    const str = "Apples are round, and apples are juicy.";
    const newstr = re.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      // CPF

      // Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2");

      // Coloca um ponto entre o terceiro e o quarto dígitos
      // de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d)/, "$1.$2");

      // Coloca um hífen entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      // console.log("V1", v);
    } else {
      // CNPJ

      // Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");

      // Coloca ponto entre o quinto e o sexto dígitos (/^(\d{2})\.(\d{3})(\d)/, "$1 $2 $3")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");

      // Coloca uma barra entre o oitavo e o nono dígitos ( /\.(\d{3})(\d)/ , ".$1/$2");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");

      // Coloca um hífen depois do bloco de quatro dígitos (/(\d{4})(\d)/, "$1-$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    if (v.length === 18) {
      // tslint:disable-next-line: no-shadowed-variable
      let cnpjv;
      cnpjv = v.replace(/[^\d]+/g, "");
      if (cnpjv.length === 14 && v.length > 16) {
        // this.getCPFCNPJ(cnpjv);
      }
    }
    if (v.length === 14) {
      let cnpjv;
      cnpjv = v.replace(/[^\d]+/g, "");
      if (cnpjv.length === 11 && v.length <= 14) {
        // this.getCPFCNPJ(cnpjv);
      }
    }

    this.formEditar.get("FcCnpj").setValue(v);
    return v;
  }
  public uploadFile(fileInput: any) {
    let size = fileInput.srcElement.files[0].size;
    console.log('SIZE',size)
    const ext = fileInput.target.files[0].name.substr(fileInput.target.files[0].name.lastIndexOf(".")+1);

    const extend = ext;


    if (ext === "pdf" || ext === "PDF") {

    } else {
      this.formEditar.get("FcArquivo").setValue("");
      const dt1 = {
        titulo: "OPS",
        status: 401,
        statusText: "Somente são permitidos arquivos .pdf",
      };
      this.msgdialogService.openDialog(dt1);
    }

    let unit = "";
    if (size < 1024) {
      size = size;
      this.fileUnit = "bytes";
      unit = "OK";
    } else if (size < 1000 * 1000) {
      size = size / 1000;
      this.fileUnit = "kB";
      unit = "OK";
    } else if (size < 1000 * 1000 * 1000) {
      size = size / 1000 / 1000;
      this.fileUnit = "MB";
      unit = "OK";
    } else {
      size = size / 1000 / 1024 / 1024;
      this.fileUnit = "GB";
    }

    size = Math.round(size);

    this.fileSize = size;
    this.fileSizeMsg = "  Tamanho do Arquivo: " + size + " " + this.fileUnit;
    if (this.fileUnit === "MB" && size > 150) {
      this.formEditar.get("FcArquivo").setValue("");
      const dt1 = {
        titulo: "OPS",
        status: 401,
        statusText:
          "Arquivo muito gande o tamanho maximo permitido é de 150 MB. \n Arquivo atual contém " +
          this.fileSizeMsg,
      };
      this.msgdialogService.openDialog(dt1);
    } else {
      this.fileData = fileInput.target.files[0];
      if (this.fileData !== null) {
        this.setProgess(this.uploadFile, 100);
      }
    }
  }
  /*  */
  public setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    // console.log('onAutocompleteSelected: ', result);
  }

  onLocationSelected(location: Location) {
    // console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  onGermanAddressMapped($event: GermanAddress) {
    //  console.log('onGermanAddressMapped', $event);
  }
  public getMotivo() {

    let TPRM = this.formEditar.get("FCtpRengIt").value;

    //console.log('Valor From Control', TPRM);
    if(this.formEditar.get("FCtpRengIt").value != undefined && TPRM.length > 0 ){

      for(let i=0; i < TPRM.length; i++){

        this.form.inreng = "S";
        this.renegocia.push({id_reneg : TPRM[i]})
          //console.log('selected', TPRM);

      }
    }else{
      this.form.inreng = "N";
      this.form.renegocia = [];
      //this.form.in
    }


}
  setFildsStep1(data) {
    //console.log('setFildsStep1',data)

    if (data.inCompAnal === "S") {
      this.checkFcCompS = true;
      this.checkFcCompJp = true;
      //this.checkContrato = true;
      //this.CheckNuga = true;

      this.formEditar.get("FcComp").setValue(data.nrItVinc);
      this.form.nrItVinc = data.nrItVinc;
    }

    this.formEditar.get("FCmprj").setValue(data.numero_mprj);
    if(data.inreng === undefined || data.inreng === null){
      this.formEditar.get("FCRengIt").setValue('N')
      this.form.inreng = "N"
    }
    this.formEditar.get("FCRengIt").setValue(data.inreng)
    let getReneg = []
    if(data.inreng === "S"){

        for(let i =0; i < data.renegocia.length; i++){
          getReneg.push(data.renegocia[i].id_reneg)
        }
        this.formEditar.controls.FCtpRengIt.setValue(getReneg);
       // console.log('xXx', getReneg)
       // console.log('XXX', this.formEditar.controls.FCtpRengIt.value)
    }


    this.form.nr_sei = data.nr_sei;
    this.getCpo(data.nucleo_gate);
    if (
      data.nucleo === 6 ||
      data.nucleo === 7 ||
      data.nucleo === 3 ||
      data.nucleo === 4
    ) {
      this.CheckNuga = true;
    } else {
      this.CheckNuga = false;
    }
    if (data.nucleo === 2 || data.nucleo === 1) {
      this.CheckNugaSaude = true;
    }
    this.form.numero_mprj = data.numero_mprj;
    this.form.nucleo_gate = data.nucleo_gate;
    const mprj = this.NumMPRJ(data.numero_mprj);

    this.formEditar.get("Fcsolicitante").setValue(data.dsSolicitante);

    this.form.solicitante = data.solicitante;

    this.formEditar.get("FCmprj").setValue(mprj);
    this.formEditar.controls.FCnucleo.setValue([data.nucleo_gate]);
    console.log("FCnucleo", data.nucleo_gate )

  }
  setcpo1(nucleo_gate, campo_1, campo_2) {
    this.contrato = new RegExp(/^Contrat*/);
    if (nucleo_gate === 6 || nucleo_gate === 7 || nucleo_gate === 3) {
      this.CheckNuga = true;
    }
    if (nucleo_gate === 2 || nucleo_gate === 1) {
      this.CheckNugaSaude = true;
    }

    this.apiService.getCpo1(nucleo_gate).subscribe((res: CPO1[]) => {
      this.cpo1 = res;
      this.cpo1.filter((proj) => {
        if (proj.dk.toString() === campo_1.toString()) {

          this.formEditar.get("FCcpo1").setValue(proj.ds);
          this.form.campo_1 = proj.dk;
          if (this.contrato.test(proj.ds)) {
            this.checkContrato = true;
          }
        }
      });
    });

  }
  setCpo2(data) {
    this.apiService
      .getCpo2(0, data.campo_1)
      .subscribe((res: CPO2[]) => {
        this.cpo2 = res;
        this.cpo2.filter((cp2) => {
          if (cp2.dk.toString().indexOf(data.campo_2) > -1) {
            this.formEditar.get("FCcpo2").setValue(cp2.ds);
            this.form.campo_2 = cp2.dk;
          }
        });
      });
  }
  setFildsStep2(data) {
    /*  */
    //console.log("DATA", data)
    this.formEditar.get("FCcpo2").setValue(data.dsCampo_2);
    let NUGA_TEC = []
    //this.setNuga();
    data.tecnicos.map((res : any)=>{
      NUGA_TEC.push(parseInt(res.nuga_dk))
    })
    //this.formEditar.controls.FCnucleo.setValue(NUGA_TEC);
    this.setcpo1(data.nucleo_gate, data.campo_1, data.campo_2);
    this.setCpo2(data);
    this.formEditar.controls.FCtpInfo.setValue(data.itcn_IN_TP_INFO)
    data.opiniao_tecnica.map((srvTec) => {
      if(srvTec.id_servico == 400){
        this.itcn_dt = true;
      }
      //console.log("Dados SRV OP", this.dados);
      if (srvTec.id_opiniao > 0 && srvTec.id_servico > 0) {
        this.OptecServ.push({
          id_servico: srvTec.id_servico,
          id_opiniao: srvTec.id_opiniao,
          cdmatricula: srvTec.cdmatricula
        });
        this.servitec.push({ id: srvTec.id_servico });
        this.dados.push({
          position: this.dataTable.length + 1,
          servico: srvTec.serv_tec,
          opiniao: srvTec.op_tec,
          tecnico: srvTec.tecnico

        });
        if (srvTec.id_opiniao == 5) {
          this.CompOp = true;
        }
      }
    });
    //console.log("Dados SRV OP", this.dados);
    //console.log("servitec", this.servitec);
    console.log("TP Info", data);
    this.form.opiniao_tecnica = this.OptecServ;
    this.form.servicos_tecnicos = this.servitec;
    this.form.tpInfo = data.itcn_IN_TP_INFO;
    this.tpInfo = data.itcn_IN_TP_INFO;
    this.nr_diretriz = data.itcn_NR_DIRETRIZ;
    this.nr_sintese = data.itcn_NR_SINTESE;
    this.dataTable = this.dados;
    this.dataSource = new MatTableDataSource<Element>(this.dataTable);
    if (data.complemento_opiniao_tecnica != "") {
      //console.log(data.complemento_opiniao_tecnica);
      this.CompOp = true;
      this.formEditar
        .get("FcCompTec")
        .setValue(data.complemento_opiniao_tecnica);
    }

    console.log('this.tecnicos',this.tecnicos)
    data.tecnicos.map((tec) => {
      if (tec.id !== 0) {
        tec.nmTecnico;
        this.tecnico.push({ id: tec.id });
        this.dadosTec.push({
          position: this.dataTableTecnicos.length + 1,
          tecnico: tec.nmTecnico,
        });
        this.dataTableTecnicos = this.dadosTec;
        this.dataSourceTecnico = new MatTableDataSource<ElementTec>(
          this.dataTableTecnicos
        );
      }
      /*
      this.tecnicos.filter(tecr => {
        if (tecr.matric.toString().indexOf(tec.id) > -1) {
          this.tecnico.push({ id: tec.id });
          this.dadosTec.push({ position: this.dataTableTecnicos.length + 1, tecnico: tecr.nome });
          this.dataTableTecnicos = this.dadosTec;
          this.dataSourceTecnico = new MatTableDataSource<ElementTec>(this.dataTableTecnicos);
        }
      })
      */
    });
  }
  setFildsStep3(data) {
    //console.log('dadosCPF',data.cpfs_cnpjs)
    data.cpfs_cnpjs.map((x) => {
      this.dadosCPF.push({
        position: this.dataTableCPF.length + 1,
        cpf_cnpj: x.cpf_cnpj,
        nm_contratada: x.nm_contratada,
        num_contratos: x.num_contratos,
        num_contratos_dano: x.num_contratos_dano,
        val_dano: x.val_dano,
      });
      this.dataTableCPF = this.dadosCPF;
      // console.log('dadosCPF',this.dadosCPF)
      this.dataSourceCPF = new MatTableDataSource<Element2>(this.dataTableCPF);
      this.cpfs_cnpjs.push({
        cpf_cnpj: x.cpf_cnpj,
        nm_contratada: x.nm_contratada,
        num_contratos: x.num_contratos,
        num_contratos_dano: x.num_contratos_dano,
        val_dano: x.val_dano,
      });
    });

    //   console.log('dataSourceCPF', this.dataSourceCPF.data.length)

    if (this.dataTableCPF.length > 0) {
      const el = document.getElementById('demo');
      el.setAttribute('class', 'collapse show');
      this.form.nvl_proced = null;
    } else {
      document.getElementById('demo').setAttribute('class', 'collapse');
      this.checkContrato = false;
      this.formEditar.get("FcNvl_dano").setValidators(Validators.required);
    }
    if (data.endereco.tipo_end == "") {
      if (data.endereco.cep !== "") {
        this.ckeckEnd = true;
        this.checkLat = false;
        this.formEditar.get("FcEndCep").setValue(data.endereco.cep.toUpperCase());
        this.formEditar
          .get("FcEndLogradouro")
          .setValue(data.endereco.logradouro.toUpperCase());
        this.formEditar
          .get("FcEndNumero")
          .setValue(data.endereco.numero.toUpperCase());
        this.formEditar
          .get("FcEndComplemento")
          .setValue(data.endereco.complemento.toUpperCase());
        this.formEditar
          .get("FcEndBairro")
          .setValue(data.endereco.bairro.toUpperCase());

        this.endereco.logradouro = data.endereco.logradouro.toUpperCase();
        this.endereco.numero = data.endereco.numero.toUpperCase();
        this.endereco.complemento = data.endereco.complemento.toUpperCase();
        this.endereco.bairro = data.endereco.bairro.toUpperCase();
        this.endereco.municipio = data.endereco.municipio;
        this.endereco.cep = data.endereco.cep;
        this.formEditar.get("FcEndRadio").setValue("E");
      }

      if (data.endereco.latitude !== "0.0") {
        this.checkLat = true;
        const str = data.endereco.latitude;
        const newstr = str.replace("-", "");
        this.endereco.cep = newstr;
        this.latitude = parseFloat(data.endereco.latitude);
        this.longitude = parseFloat(data.endereco.longitude);
        if (data.endereco.latitude !== "0.0" || data.endereco.latitude !== "") {
          this.formEditar.get("FcEndLatNum").setValue(this.latitude);
        }
        if (data.endereco.longitude !== "0.0" || data.endereco.longitude !== "") {
          this.formEditar.get("FcEndLongNum").setValue(this.longitude);
        }

        this.getAddress(this.latitude, this.longitude, "city");
        this.formEditar.get("FcEndRadio").setValue("L");
      }
    } else if (data.endereco.tipo_end !== "") {

      if (data.endereco.tipo_end == "L") {
        this.formEditar.get("FcEndLatNum").setValue(this.latitude);
        this.formEditar.get("FcEndLongNum").setValue(this.longitude);
      }
      else {
        this.formEditar.get("FcEndCep").setValue(data.endereco.cep.toUpperCase());
        this.formEditar
          .get("FcEndLogradouro")
          .setValue(data.endereco.logradouro.toUpperCase());
        this.formEditar
          .get("FcEndNumero")
          .setValue(data.endereco.numero.toUpperCase());
        this.formEditar
          .get("FcEndComplemento")
          .setValue(data.endereco.complemento.toUpperCase());
        this.formEditar
          .get("FcEndBairro")
          .setValue(data.endereco.bairro.toUpperCase());
      }
      const str = data.endereco.latitude;
      this.endereco.logradouro = data.endereco.logradouro.toUpperCase();
      this.endereco.numero = data.endereco.numero.toUpperCase();
      this.endereco.complemento = data.endereco.complemento.toUpperCase();
      this.endereco.bairro = data.endereco.bairro.toUpperCase();
      this.endereco.municipio = data.endereco.municipio;
      this.endereco.cep = data.endereco.cep;
      this.endereco.tipo_end = data.endereco.tipo_end;
      const newstr = this.endereco.cep.replace("-", "");
      this.endereco.cep = newstr;
      this.latitude = parseFloat(data.endereco.latitude);
      this.longitude = parseFloat(data.endereco.longitude);
      this.endereco.latitude = this.latitude;
      this.endereco.longitude = this.longitude;
      this.formEditar.get("FcEndLatNum").setValue(this.latitude);
      this.formEditar.get("FcEndLongNum").setValue(this.longitude);

      if (data.endereco.tipo_end == "T" || data.endereco.tipo_end == 'E') {
        this.ckeckEnd = true;
        this.checkLat = false;
        this.formEditar.get("FcEndRadio").setValue("E");
      } else {
        this.ckeckEnd = false;
        this.checkLat = true;
        this.getAddress(this.latitude, this.longitude, "city");
        this.formEditar.get("FcEndRadio").setValue(data.endereco.tipo_end);
      }
      //console.log("SET TIPO END ", this.endereco)
      //console.log("RECEIVE TIPO END ", data.endereco)
    }
    let NUGA_TEC = []
    //this.setNuga();
    data.tecnicos.map((res : any)=>{
      NUGA_TEC.push(parseInt(res.nuga_dk))
    })
    this.formEditar.controls.FCnucleo.setValue(NUGA_TEC);
    //this.getCpo(NUGA_TEC)
    if (data.dano_erario === "S") {
      this.checkDanoS = true;
      this.formEditar.get("FcDano").setValue(data.valor_dano);
    }
    if (data.nrItVinc !== "") {
      this.checkFcCompJp = true;
      this.checkFcCompS = true;
    }
  }

  fillfilds(data) {
    //console.log("data", data);
    this.contrato = new RegExp(/^Contrat*/);
    let SEI = "";
    // Seta radio butons step 3
    this.formEditar.get("FcNvl_dano").setValue(data.nvl_proced);
    this.formEditar.get("FcTpContrato").setValue(data.tipoContrato);
    if (data.danoZero != "") {
      this.formEditar.get("Fcdzero").setValue(data.danoZero);
    }
    if (data.indicioDano > 0) {
      this.formEditar.get("FcValIndDano").setValue(data.indicioDano);
    }
    if (data.atualValDano > 0) {
      this.formEditar.get("FcAtualValDano").setValue(data.atualValDano);
    }
    if (data.compDano > 0) {
      this.formEditar.get("FcValCompDano").setValue(data.compDano);
    }
    if (this.contrato.test(data.dsCampo_1)) {
      this.checkContrato = true;
    } else {
      this.checkContrato = false;
    }

    this.numSei.filter((sei) => {
      if (sei.protocolo.indexOf(data.nr_sei) > -1) {
        this.formEditar.get("FCnumeroSei").setValue(data.nr_sei);
        SEI = data.nr_sei;
      }
    });
    this.DataCity.filter((option) => {
      if (option.id == data.endereco.municipio) {
        this.endereco.municipio = option.id;
        this.formEditar.get("FcEndCidade").setValue(option.Cidade);
      }
    });
    this.formEditar.get("FCmprj").setValue(data.numero_mprj);
    this.NucleoTec = data.nucleo_gate
    this.getCpo(data.nucleo_gate);
    if (
      data.nucleo === 6 ||
      data.nucleo === 7 ||
      data.nucleo === 3 ||
      data.nucleo === 4
    ) {
      this.CheckNuga = true;
    } else {
      this.CheckNuga = false;
    }
    if (data.nucleo === 2 || data.nucleo === 1) {
      this.CheckNugaSaude = true;
    }
    this.form.numero_mprj = data.numero_mprj;
    this.form.nucleo_gate = data.nucleo_gate;
    const mprj = this.NumMPRJ(data.numero_mprj);
    this.formEditar.controls.FCnucleo.setValue([data.nucleo_gate]);

    this.formEditar.get("Fcsolicitante").setValue(data.dsSolicitante);
    this.form.solicitante = data.solicitante;
    this.formEditar.get("FCmprj").setValue(mprj);

    if (SEI === "" && data.nr_sei !== "") {
      this.validateSei = true;
      this.formEditar.get("FCnumeroSei2").setValue(data.nr_sei);
    }

    if (data.nrItVinc !== "") {
      this.checkFcCompS = true;
      this.checkFcCompJp = true;
      this.checkContrato = true;
      this.CheckNuga = true;

      // console.log('NR sei', data.nrItVinc)
      this.formEditar.get("FcComp").setValue(data.nrItVinc);
    }

    if (
      data.nucleo_gate === 6 ||
      data.nucleo_gate === 7 ||
      data.nucleo_gate === 3
    ) {
      this.CheckNuga = true;
    }
    if (data.nucleo_gate === 2 || data.nucleo_gate === 1) {
      this.CheckNugaSaude = true;
    }

    this.formEditar.controls.FCnucleo.setValue([data.nucleo_gate]);
    this.setFildsStep1(data);
    this.setFildsStep2(data);
    this.setFildsStep3(data);
  }
}

export interface Element {
  servico: string;
  position: number;
  opiniao: string;
}

export interface ElementTec {
  position: number;
  tecnico: string;
}
export interface Element2 {
  cpf_cnpj: string;
  position: number;
  nm_contratada: string;
  num_contratos: number;
  num_contratos_dano: number;
  val_dano: string;
}

export interface TipoContrato {
  value: string;
  viewValue: string;
}
